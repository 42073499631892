import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Divider, Input, List, Space, Spin, Tag } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import PurchaseButton from './purchase_button';
import OrderTicketUserList from './order_ticket_user_list';
import OrderAddOnList from './order_add_on_list';
import { reset_purchase_detail, set_purchase_detail } from '../../../actions/purchase.action';
import PromoCode from './promo_code';
import PurchaseTaxes from './purchase_taxes';
import ticketService from '../../../services/ticketing/ticket.service';
import numberHelper from '../../../helpers/functions/number.helper';
import globalHelper from '../../../helpers/functions/global.helper';
import { price_calculating, set_remaining_promo_tickets } from '../../../actions/ticket.action';
import CartDetail from './cart_detail';
import SummaryDateCalendar from './summary_date_calendar';
import moment from 'moment';
import PromoCodeDefault from './promo_code_default';

const PurchaseSummary = ({ park, bannerQuill, }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;
    const headerRef = useRef();
    const footerRef = useRef();
    const cardRef = useRef();

    const [state, setState] = useState({
        loading : false,

        // summary amount
        discount_ticket_users : [],
        discount_add_ons : [],
        sub_total : 0,
        discount_amount : 0,
        total_amount : 0,
        taxes : [],
    })

    const contentHeight = cardRef?.current?.clientHeight - (headerRef?.current?.clientHeight + footerRef?.current?.clientHeight);
    const promotion_code = purchaseRedux?.promotion_code;
    const is_stack = promotion_code?.is_stack;
    const ticket_price = (purchaseRedux?.ticket_users?.map(x => (( (is_stack ? x.price : x?.actual_price) ?? 0) * x.quantity))?.reduce((a, b) => a + b, 0));
    const add_on_price = (purchaseRedux?.add_ons?.map(x => ((x?.price ?? 0) * x?.quantity))?.reduce((a, b) => a + b, 0));
    const price =  ticket_price + add_on_price;

    const is_item_length = (purchaseRedux?.ticket_users?.length > 0 || purchaseRedux?.add_ons?.length > 0);

    // ticket type for getting date range
    const ticket_types = park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id);

    // preference
    const preference = ticketRedux?.preference_groups?.flatMap(x => x?.preferences)?.find(x => x.preference_id == purchaseRedux?.preference_id);

    const initializeTotalPrice = async () => {
        //make sure final price is same with price calculated in backend
        const { ticket_users, add_ons, promotion_code, park_id, visit_date, } = purchaseRedux;
        if(park_id){ //park id is compulsory to prevent duplicates request call
            dispatch(price_calculating(true));
            setState(state => ({...state, loading : true, }));
            const calculatePricingResponse = await ticketService?.calculateTicketPricing({
                park_id,
                ticket_users,
                add_ons,
                visit_date : globalHelper?.parseDate(visit_date),
                promo_code : promotion_code?.promo_code,
            });
    
            const { calculate_promotion_amount, sub_total, total_amount, total_ticket_amount, total_add_on_amount, purchaseable_promo_ticket_count, taxes, } = calculatePricingResponse;
            const { total_discount_amount : discount_amount, ticket_user_amount, add_on_amount,  } = calculate_promotion_amount;
            setState(state => ({...state, loading : false, discount_ticket_users : ticket_user_amount, discount_add_ons : add_on_amount, sub_total, total_amount, discount_amount, taxes, }));
            dispatch(set_remaining_promo_tickets(purchaseable_promo_ticket_count));
        }
    }

    //setting price
    useEffect(() => {
        initializeTotalPrice();
    }, [purchaseRedux?.visit_date, JSON.stringify(purchaseRedux?.ticket_users), JSON.stringify(purchaseRedux?.add_ons), purchaseRedux?.promotion_code,]) //when price and promotion code is changed, re-calculate final price
 
    return (
        <>
            <Spin spinning={state?.loading} indicator={<LoadingOutlined />}>
                <div
                className={`cart-section ${bannerQuill && (`banner-text`)}`}
                >
                    <Card
                    styles={{ body : { padding : 0, }, }}
                    style={{borderColor:'#cbd5e0', height:'100%', borderWidth: 1.5}}
                    ref={cardRef}
                    >
                        <div style={{height:'inherit',}}>
                            <div ref={headerRef} style={{textAlign:'left', ...(park?.cover_image && ({ backgroundImage : `url(${park?.cover_image})`, backgroundPosition : 'center', backgroundSize : 'cover', backgroundRepeat :'no-repeat', borderRadius : '6px 6px 0px 0', })) }}>
                                <div {...park?.cover_image && ({ className : 'card-overlay', })} style={{ padding:'12px 24px', borderRadius : '6px 6px 0px 0', }}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div>
                                            <Space align='start' size={20}>
                                                <SummaryDateCalendar date={globalHelper.parseDate(purchaseRedux?.visit_date)} />
                                                <div>
                                                    <div>
                                                        <span style={{fontSize:14, color : (park?.cover_image ? ('#fff') : ('var(--secondary-text-color)')), fontWeight:'normal'}}>
                                                            {`Ticket Type : ${ticket_types?.name ?? 'N/A'}`}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <Space wrap align='center'>
                                                            <div>
                                                                <span style={{fontSize:14, color : (park?.cover_image ? ('#fff') : ('var(--secondary-text-color)')), fontWeight:'normal'}}>
                                                                    {`Visit Date : ${(`${purchaseRedux?.visit_date ?? 'N/A'} ${ticket_types?.ParkApplicableTicketType?.date_range > 0 ? `to ${moment(globalHelper.parseDate(purchaseRedux?.visit_date))?.add((ticket_types?.ParkApplicableTicketType?.date_range ?? 0), 'days')?.format("DD/MM/YYYY")}` : ''}` ?? 'N/A')}`}
                                                                </span>
                                                            </div>
                                                            {
                                                                preference &&
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <Tag style={{border : 'none', color : 'var(--main-color)' }} color={'var(--lighten-main-color)'}>
                                                                            {`${preference?.preference_group ? (`${preference?.preference_group?.name} - `) : ''} ${(preference?.name)}`}
                                                                            </Tag>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </Space>
                                                    </div>
                                                </div>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                                <Divider style={{marginBottom:0, marginTop:0}}/>
                            </div>

                            <div
                            className='summary-cart-items scroll-item'
                            style={{
                                overflowY:'auto',
                                flexGrow : 1, 
                                maxHeight: contentHeight,
                            }}
                            >
                                <div>
                                {
                                    is_item_length ?
                                    (
                                        <>
                                            <OrderTicketUserList discount_ticket_users={state?.discount_ticket_users} />
                                            <Divider style={{ margin : 0, }} />
                                            <OrderAddOnList discount_add_ons={state?.discount_add_ons} />
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div style={{display:'flex', minHeight: contentHeight, alignItems:'center', justifyContent:'center',}}>
                                                <span style={{color:'#6f7287'}}>No Item Added</span>
                                            </div>
                                        </>
                                    )
                                }
                                </div>

                            </div>

                            <div>
                                <div ref={footerRef} className='purchase-modal-footer' style={{textAlign:'left'}}>
                                    <div style={{padding:'12px 24px', height:70, display:'flex', alignItems:'center',}}>
                                        {
                                            ticketRedux?.default_promo_code ?
                                            (
                                                <>
                                                    <PromoCodeDefault />
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <PromoCode subtotal={price} size={'large'} />
                                                </>
                                            )
                                        }
                                    </div>
                                    <Divider style={{margin:0}} />
                                    <div style={{padding:'12px 24px',}}>
                                        <div>
                                            <div style={{display:'flex',  width:'100%'}}>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>Sub Total</span>
                                                </div>
                                    
                                                <div style={{marginLeft:'auto'}}>
                                                    <span className='ticket-label' style={{fontSize:14,}}>
                                                        {`${currency}${numberHelper.commaNumber(state?.sub_total)}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <div style={{display:'flex',  width:'100%'}}>
                                                <div>
                                                    <span style={{color:'var(--secondary-text-color)'}}>Discount</span>
                                                </div>
                                    
                                                <div style={{marginLeft:'auto'}}>
                                                    <span className='ticket-label' style={{fontSize:14, color:'var(--main-color)'}}>
                                                        {`-${currency}${numberHelper.commaNumber(state?.discount_amount)}`}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <PurchaseTaxes park_taxes={park?.park_taxes} taxes={state?.taxes} />
                                        </div>
                                        
                                        <div style={{display:'flex',}}>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>Total</span>
                                            </div>
                                            <div style={{marginLeft:'auto'}}>
                                                <span className='ticket-label' style={{fontSize:20, fontWeight:500}}>
                                                    {`${currency}${numberHelper.commaNumber(state?.total_amount)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    
                    <div style={{marginTop:12}}>
                        <PurchaseButton park={park} final_price={state?.total_amount} is_checkout />
                    </div>

                    {
                        (purchaseRedux?.ticket_type_id && !globalHelper?.isMobileScreen() ) &&
                        (
                            <>
                                <div style={{marginTop:8,}}>
                                    <CartDetail park={park} />
                                </div>
                            </>
                        )
                    }
                </div>
            </Spin>
        </>
    );
}

export default PurchaseSummary;