import React from 'react';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';

const NotFoundLayout = ({ queryItems }) => {
    const { redirect_url, redirect_name, title, description, } = queryItems ?? {};
    return (
        <>
            <SimpleNotFoundComponent
            redirect_url={redirect_url}
            redirect_name={redirect_name}
            title={title}
            description={description}
            />
        </>
    );
}

export default NotFoundLayout;