import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Alert, Tag } from 'antd';
import PromoCodeApplied from './promo_code_applied';
import promotionService from '../../../services/config/promotion.service';
import globalHelper from '../../../helpers/functions/global.helper';
import { set_purchase_detail } from '../../../actions/purchase.action';
import { set_remaining_promo_tickets } from '../../../actions/ticket.action';

const PromoCodeDefault = () => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);

    const [state, setState] = useState({
        loading : false,
    })

    const [error, setError] = useState({
        promotion_code_message : null,
    })

    const validatePromoCode = async (promotion_code) => {
        const { park_id, visit_date, ticket_users, open_date = false, } = purchaseRedux;
        //check promotion code available
        const promotionCodeResponse = await promotionService.validatePromoCode({
            promo_code : promotion_code,
            park_id : park_id,
            visit_date : globalHelper.parseDate(visit_date),
            ticket_users,
            open_date,
        }).catch(err => {
            setState(state => ({...state, loading : false,}))
        });

        return promotionCodeResponse;
    }

    // check is valid promotion
    const onVisitDateChange = async () => {
        setError(error => ({...error, promotion_code_message : null, }))
        const open_date = purchaseRedux?.open_date;
        
        // check on default promotion code
        const promotion_code = ticketRedux?.default_promo_code;
        const visit_date = purchaseRedux?.visit_date || open_date;
        if(promotion_code && visit_date){
            const promotionCodeResponse = await validatePromoCode(promotion_code);

            const { promotion_code : promotion_code_response, remaining, is_exceed_limit, purchaseable_ticket_count, } = promotionCodeResponse;
            setTimeout(() => {
                setState(state => ({...state, loading : false, }));
                // if is exceed limit
                if(is_exceed_limit){
                    dispatch(set_purchase_detail({...purchaseRedux, promotion_code : null, is_null : true}));
                    setError(error => ({...error, promotion_code_message : `Promo code will apply for ${remaining} tickets only`}));
                    return;
                }

                if(!promotion_code_response){
                    //no code found
                    dispatch(set_purchase_detail({...purchaseRedux, promotion_code : null, is_null : true}));
                    setError(error => ({...error, promotion_code_message : 'Promo code invalid'}));
                    return;
                }

                dispatch(set_purchase_detail({...purchaseRedux, promotion_code : promotion_code_response, }));

                // for limit
                // calculate remaining ticket can be purchased
                const remaining_purchaseable_quantity = purchaseable_ticket_count;
                dispatch(set_remaining_promo_tickets(remaining_purchaseable_quantity));
            }, 200);
        }
    }

    useEffect(() => {
        onVisitDateChange();
    }, [purchaseRedux?.visit_date, ticketRedux?.default_promo_code])

    return (
        <>
            {
                purchaseRedux?.promotion_code ?
                (
                    <>
                        <div>
                            <PromoCodeApplied isRemove={false} />
                        </div>
                    </>
                )
                :
                (
                    <>
                        {
                            error?.promotion_code_message ? (
                                <>
                                    <EntranceMotion style={{ width : '100%', }}>
                                        <Alert
                                        type='error'
                                        style={{ width : 'inherit', }}
                                        message={(
                                            <>
                                                <div style={{ fontSize : 12, fontWeight : 500, }}>
                                                    {error?.promotion_code_message}
                                                </div>
                                                {/* <div>
                                                    <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>
                                                        Please select different promo code or visit date
                                                    </span>
                                                </div> */}
                                            </>
                                        )}
                                        />
                                    </EntranceMotion>
                                </>
                            )
                            :
                            (
                                <>
                                    <EntranceMotion>
                                        <span style={{color:'var(--secondary-text-color)'}}>Promo Code <Tag color='orange' style={{border:'none', margin : 0,}}>{ticketRedux?.default_promo_code}</Tag> will be applied after visit date is selected</span>
                                    </EntranceMotion>
                                </>
                            )
                        }
                    </>
                )
            }
        </>
    );
}

export default PromoCodeDefault;