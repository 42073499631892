import { Alert, Button, Form, Input, Space, Tag, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import promotionService from '../../../services/config/promotion.service';
import { CloseOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global.helper';
import { set_remaining_promo_tickets } from '../../../actions/ticket.action';
import PromoCodeApplied from './promo_code_applied';

const PromoCode = () => {
    const purchaseRedux = useSelector(state => state?.purchase);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [state, setState] = useState({
        loading : false,
        promotion_code : null,

        error : null,
    })

    const [error, setError] = useState({
        promotion_code_message : null,
    })

    const applyPromo = async () => {
        setState(state => ({...state, loading : true, }));
        
        const { promotion_code, } = form.getFieldsValue();
        const promotionCodeResponse = await validatePromoCode(promotion_code);

        const { promotion_code : promotion_code_response, remaining, is_exceed_limit, purchaseable_ticket_count, } = promotionCodeResponse;

        setTimeout(() => {
            setState(state => ({...state, loading : false, }));
            // if is exceed limit
            if(is_exceed_limit){
                setError(error => ({...error, promotion_code_message : `Promo code will apply for ${remaining} tickets only`}));
                return;
            }

            if(!promotion_code_response){
                //no code found
                setError(error => ({...error, promotion_code_message : 'Promo code invalid'}));
                return;
            }

            dispatch(set_purchase_detail({...purchaseRedux, promotion_code : promotion_code_response, }));

            // for limit
            // calculate remaining ticket can be purchased
            const remaining_purchaseable_quantity = purchaseable_ticket_count;
            dispatch(set_remaining_promo_tickets(remaining_purchaseable_quantity));
        }, 200);  
    }

    const onPromotionCodeChange = (value) => {
        setState(state => ({...state, promotion_code : value, }));
        setError(error => ({...error, promotion_code_message : null,}));
    }

    const removePromotion = () => {
        setState(state => ({...state, promotion_code : null,}));
        setError(error => ({...error, promotion_code_message : null,}));
        dispatch(set_purchase_detail({...purchaseRedux, promotion_code : null, is_null : true}));
    }

    // check is valid promotion
    const onVisitDateChange = async () => {
        // check on default promotion code
        const applied_promo_code = purchaseRedux?.promotion_code?.promo_code;
        if(applied_promo_code){
            const promotionCodeResponse = await validatePromoCode(purchaseRedux?.promotion_code?.promo_code);
            if(!promotionCodeResponse?.promotion_code){
                removePromotion();
            }
        }
    }

    const validatePromoCode = async (promotion_code) => {
        const { park_id, visit_date, ticket_users, open_date = false, } = purchaseRedux;
        //check promotion code available
        const promotionCodeResponse = await promotionService.validatePromoCode({
            promo_code : promotion_code,
            park_id : park_id,
            visit_date : globalHelper.parseDate(visit_date),
            ticket_users,
            open_date,
        }).catch(err => {
            setState(state => ({...state, loading : false,}))
        });

        return promotionCodeResponse;
    }
    
    // remove promotion when visit date is changed
    useEffect(() => {
        onVisitDateChange();
    }, [purchaseRedux?.visit_date])

    return (
        <>
            <Form
            form={form}
            onFinish={applyPromo}
            style={{width:'100%'}}
            >
                <div style={{ display:'flex', gap:8, flex : 0, }}>
                {
                    purchaseRedux?.promotion_code ?
                    (
                        <>
                            <PromoCodeApplied removePromotion={removePromotion} />
                        </>
                    )
                    :
                    (
                        <>
                            <div style={{width:'100%', flex : 1,}}>
                                <EntranceMotion>
                                    <Form.Item name="promotion_code" {...error?.promotion_code_message && { help : error?.promotion_code_message, validateStatus : 'error' }}>
                                        <Input
                                        // size={size ?? 'middle'}
                                        placeholder='Promo Code'
                                        onChange={(e) => onPromotionCodeChange(e.target?.value)}
                                        style={{...error?.promotion_code_message && ({ background: '#ffeded' })}}
                                        />
                                    </Form.Item>
                                </EntranceMotion>
                            </div>
                            <div>
                                <EntranceMotion delay={0.1}>
                                    <Form.Item>
                                        <Button
                                        htmlType='submit'
                                        loading={state?.loading}
                                        disabled={!form?.getFieldValue('promotion_code')}
                                        type="primary"
                                        style={{color:'var(--main-text-color)',paddingLeft:24, paddingRight:24, border:'none'}}
                                        >
                                            Apply
                                        </Button>
                                    </Form.Item>
                                </EntranceMotion>
                            </div>
                        </>
                    )
                }
                </div>
            </Form>
        </>
    );
}

export default PromoCode;