import React, { useEffect, useState } from 'react';
import numberHelper from '../../../helpers/functions/number.helper';
import { useSelector } from 'react-redux';
import ticketService from '../../../services/ticketing/ticket.service';
import { Skeleton } from 'antd';

const PurchaseTaxes = ({ park_taxes, taxes, }) => {
    const ticketRedux = useSelector(state => state.ticket);
    const purchaseRedux = useSelector(state => state?.purchase);
    const currency = ticketRedux?.park?.currency?.symbol;

    const [state, setState] = useState({
        loading : false,
        taxes : [],
    })

    const getTaxes = async () => {
        setState(state => ({...state, loading : true, }))
        const { park_id, ticket_users, add_ons, } = purchaseRedux ?? {};

        const ticket_applicable_users = ticket_users?.map(x => x?.ticket_applicable_user_id);
        const add_on_setups = add_ons?.map(x => x?.add_on_setup_id);
        const taxesResponse = await ticketService?.getPurchaseTaxes({
           park_id,
           ticket_applicable_user_ids : ticket_applicable_users?.join(","),
           add_on_setup_ids : add_on_setups?.join(","),
        });

        setState(state => ({...state, loading : false, taxes : taxesResponse?.park_taxes,}));
    }

    useEffect(() => {
        if(purchaseRedux?.park_id){
            getTaxes();
        }
    }, [JSON.stringify(purchaseRedux?.ticket_users), JSON.stringify(purchaseRedux?.add_ons)])

    return (
        <>
            {
                state?.loading ?
                (
                    <Loading />
                )
                :
                (
                    <>
                        {
                            state?.taxes?.map((park_tax, index) => {
                                const { park_tax_id, name, percentage, value } = park_tax;
                                const cal_tax = taxes?.find(x => x.park_tax_id === park_tax_id);
                                const tax_amount = cal_tax?.amount ?? 0;

                                return (
                                    <div key={index}>
                                        <div style={{display:'flex',  width:'100%'}}>
                                            <div>
                                                <span style={{color:'var(--secondary-text-color)'}}>{`${name}`}</span>
                                            </div>
                                
                                            <div style={{marginLeft:'auto'}}>
                                                <span className='ticket-label' style={{fontSize:14,}}>
                                                    {`${currency}${numberHelper?.commaNumber(tax_amount, 2)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            }
        </>
    );
}

const Loading = () => {
    return (
        <>
            <Skeleton title={false} paragraph={{ rows : 1, }} />
        </>
    );
}

export default PurchaseTaxes;