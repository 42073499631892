import { Button, Col, Divider, Drawer, notification, Row, Space } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import OpenDateTicketType from './open_date_ticket_type';
import { useDispatch, useSelector } from 'react-redux';
import { set_default_promo_code, set_park, set_special_link } from '../../../actions/ticket.action';
import parkService from '../../../services/config/park.service';
import { set_purchase_detail } from '../../../actions/purchase.action';
import axios from 'axios';
import OpenDateParkApplicableTicketTypeItem from './open_date_park_applicable_ticket_type_item';
import globalHelper from '../../../helpers/functions/global.helper';
import OpenDateVisitor from './open_date_visitor';
import OpenDateLiabilityWaiver from './open_date_liability_waiver';
import PurchaseButton from '../../purchase/components/purchase_button';
import OpenDatePurchaseSummary from './open_date_purchase_summary';
import OpenDateSummaryDateCalendar from './open_date_summary_date_calendar';
import { CloseOutlined } from '@ant-design/icons';
import ticketService from '../../../services/ticketing/ticket.service';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import PromoCode from '../../purchase/components/promo_code';
import numberHelper from '../../../helpers/functions/number.helper';
import PurchaseTaxes from '../../purchase/components/purchase_taxes';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';
import TicketTypeLoading from '../../purchase/components/ticket_type_loading';
import OpenDateAlert from './open_date_alert';
import PromoCodeDefault from '../../purchase/components/promo_code_default';

const OpenDatePurchaseLayout = ({ park_code, queryItems, }) => {
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);
    const dispatch = useDispatch();

    // reference
    const ticket_type_ref = useRef();
    const ticket_user_type_ref = useRef();
    // drawer reference
    const drawer_header_ref = useRef();
    const drawer_footer_ref = useRef();

    const [state, setState] = useState({
        loading : true,
        park : null,
        is_open_date_setting_valid : false,

        // special link
        special_link : null,
        special_link_expired : false,
        special_link_used : false,

        open_summary_drawer : false,
        rule_loading : false,

        // set calculated amount for footer
        total_amount : 0,
        sub_total : 0,
        discount_amount : 0,
        taxes : [],
    })

    // memo
    const bannerHeight = (ticketRedux?.park?.banner_text ? (globalHelper?.isMobileScreen() ? 64 : 40) : 0);
    const currency = useMemo(() => {
        return state?.park?.currency?.symbol;
    }, [state?.park])

    const ticket_type = useMemo(() => {
        return (state?.park?.ticket_types?.find(x => x?.ticket_type_id === purchaseRedux?.ticket_type_id))
    }, [purchaseRedux?.ticket_type_id])

    const fetchPark = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService.getPark({
            park_code : park_code,
            is_server_maintenance : false,
        });
        const park_id = parkResponse?.parks?.park_id;

        // fetch open date setting
        const parkOpenDateSetting = await parkService?.getParkOpenDateSetting(park_id);
        
        // default promotion code, do checking on promotion summary
        dispatch(set_default_promo_code(queryItems?.default_promo_code));
        dispatch(set_purchase_detail({ park_id : park_id, open_date : true, }));
        dispatch(set_park(parkResponse?.parks))
        setTimeout(() => {
            setState(state => ({...state,
                loading : false,
                park : parkResponse?.parks,
                is_open_date_setting_valid : parkOpenDateSetting?.park_open_date_setting?.is_active,
            }))
        }, 500)
    }

    const openSummary = async (open) => {
        // check rules for purchase tickets
        setState(state => ({...state, rule_loading : true}));
        const { ticket_type_id, park_id, ticket_users, visit_date, email, liability_waiver_checked, preference_id, name, open_date, } = purchaseRedux;
        const { special_link } = ticketRedux;
        
        const checkRuleResponse = await ticketService.checkRules({
            ticket_type_id, park_id, ticket_users, email, special_link_id : special_link?.special_link_id,
            liability_waiver_checked, preference_id, name, open_date,
        }).catch(err => {
            console.error(err);
        });

        //if there is error
        if(checkRuleResponse?.error){
            const error = checkRuleResponse?.error[0];
            const { message, type } = error ?? {};
            notification.warning({
                message : 'Oops',
                description : message,
            });

            setState(state => ({...state, rule_loading : false}));
            dispatch(set_purchase_error({
                [type] : true,
            }))
        }else{
            setState(state => ({...state, open_summary_drawer : open, rule_loading : false, }));
        }
        
    }

    useEffect(() => {
        fetchPark();
    }, [])

    useEffect(() => { //set header of axios
        axios.defaults.headers.common['park_id'] = state?.park?.park_id;

        return ()=>{
            axios.defaults.headers.common['park_id'] = null;
        }
    }, [state?.park])

    useEffect(() => {
        if(purchaseRedux?.ticket_type_id){
            const headerHeight = (document.querySelector('header')?.offsetHeight ?? 0) + bannerHeight;
            const topOffset = ticket_user_type_ref.current.getBoundingClientRect().top + window.scrollY - (headerHeight + 40);
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }
    }, [purchaseRedux?.ticket_type_id])

    return (
        <>
            <div style={{ paddingBlock: 12, ...(globalHelper?.isMobileScreen() && ({ padding: 24, })) }}>
                <Row justify={'center'}>
                    <Col xs={24} md={18} xl={14} span={14}>
                        {
                            state?.loading ?
                            (
                                <>
                                    <TicketTypeLoading />
                                </>
                            )
                            :
                            (
                                <>
                                    <div>
                                        {
                                            state?.is_open_date_setting_valid ?
                                                (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <OpenDateAlert />
                                                            </div>
                                                            <div ref={ticket_type_ref} style={{ marginTop : 18, }}>
                                                                <OpenDateTicketType park={state?.park} />
                                                            </div>
                                                            <div ref={ticket_user_type_ref}>
                                                                {
                                                                    purchaseRedux?.ticket_type_id && (
                                                                        <>
                                                                            <OpenDateParkApplicableTicketTypeItem ticket_type={state?.park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id)} />
                                                                            <OpenDateVisitor />
                                                                            <OpenDateLiabilityWaiver park={state?.park} />

                                                                            <div style={{ marginTop: 48, }}>
                                                                                {/* <OpenDatePurchaseButton /> */}
                                                                                <Button
                                                                                    loading={state?.rule_loading}
                                                                                    onClick={() => openSummary(true)}
                                                                                    // onClick={purchaseTicket}
                                                                                    size='large'
                                                                                    type="primary"
                                                                                    style={{ width: '100%', border: 'none' }}
                                                                                // disabled={is_disabled}
                                                                                // loading={state?.save_loading}
                                                                                >
                                                                                    <span style={{ color: "var(--main-text-color)", fontWeight: 500 }}>Review Order</span>
                                                                                </Button>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <SimpleNotFoundComponent
                                                            title={`Invalid Open Date`}
                                                            description={`Something went wrong while configuring the park. Please contact administrator.`}
                                                            isBack
                                                        />
                                                    </>
                                                )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </div>

            <Drawer
            open={state?.open_summary_drawer}
            className='purchase-summary-drawer'
            width={globalHelper?.isMobileScreen() ? '100%' : 500}
            onClose={() => openSummary(false)}
            closeIcon={(
                <>
                    <CloseOutlined style={{ color : (state?.park?.cover_image ? '#fff' : 'var(--secondary-text-color)'), }} />
                </>
            )}
            styles={{ header : { padding : 0, }, body : { padding : 0, }, footer : { padding : 0, } }}
            title={(
                <>
                    <div ref={drawer_header_ref} style={{textAlign:'left', ...(state?.park?.cover_image && ({ backgroundImage : `url(${state?.park?.cover_image})`, backgroundPosition : 'center', backgroundSize : 'cover', backgroundRepeat :'no-repeat', borderRadius : '6px 6px 0px 0', })) }}>
                        <div {...state?.park?.cover_image && ({ className : 'card-overlay', })} style={{ padding:'12px 24px', }}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div>
                                    <Space align='start' size={20}>
                                        <OpenDateSummaryDateCalendar />
                                        {/* <SummaryDateCalendar date={globalHelper.parseDate(purchaseRedux?.visit_date)} /> */}
                                        <div>
                                            <span style={{fontSize:14, color : (state?.park?.cover_image ? ('#fff') : ('var(--secondary-text-color)')), fontWeight:'normal'}}>
                                                {`Ticket Type : ${ticket_type?.name ?? 'N/A'}`}
                                            </span>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            footer={(
                <>
                    <div ref={drawer_footer_ref}>
                        <div>
                            <div style={{ textAlign: 'left', padding: 0, position: 'initial', paddingBlock : 12, paddingInline: 24, }}>
                                <div style={{ height: 70, display: 'flex', alignItems: 'center', }}>
                                    {
                                        ticketRedux?.default_promo_code ?
                                        (
                                            <>
                                                <PromoCodeDefault />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <PromoCode subtotal={0} size={'large'} />
                                            </>
                                        )
                                    }
                                </div>
                                <div>
                                    <div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div>
                                                <span style={{ color: 'var(--secondary-text-color)' }}>Sub Total</span>
                                            </div>

                                            <div style={{ marginLeft: 'auto' }}>
                                                <span className='ticket-label' style={{ fontSize: 14, }}>
                                                    {`${currency}${numberHelper.commaNumber(state?.sub_total)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <div>
                                                <span style={{ color: 'var(--secondary-text-color)' }}>Discount</span>
                                            </div>

                                            <div style={{ marginLeft: 'auto' }}>
                                                <span className='ticket-label' style={{ fontSize: 14, color: 'var(--main-color)' }}>
                                                    {`-${currency}${numberHelper.commaNumber(state?.discount_amount)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <PurchaseTaxes park_taxes={state?.park?.park_taxes} taxes={state?.taxes} />
                                    </div>

                                    <div style={{ display: 'flex', }}>
                                        <div>
                                            <span style={{ color: 'var(--secondary-text-color)' }}>Total</span>
                                        </div>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <span className='ticket-label' style={{ fontSize: 20, fontWeight: 500, }}>
                                                {`${currency}${numberHelper.commaNumber(state?.total_amount)}`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: '12px 24px', }}>
                            <PurchaseButton is_checkout park={state?.park} final_price={state?.total_amount} />
                        </div>
                    </div>
                </>
            )}
            >
                <OpenDatePurchaseSummary
                key={state?.open_summary_drawer}
                park={state?.park}
                setPurchaseAmount={({ total_amount, sub_total, taxes, discount_amount, }) => setState(state => ({...state, total_amount, sub_total, discount_amount, taxes, }))}
                />
            </Drawer>
        </>
    );
}

export default OpenDatePurchaseLayout;