import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const PromoCodeApplied = ({ removePromotion = () => {}, isRemove = true, }) => {
    return (
        <>
            <div style={{display:'flex',  width:'100%'}}>
                <div>
                    <EntranceMotion>
                        <span style={{color:'var(--secondary-text-color)'}}>Promo Code Applied 🎉</span>
                    </EntranceMotion>
                </div>
                <div style={{marginLeft:'auto'}}>
                    {
                        isRemove && (
                            <>
                                <EntranceMotion delay={0.1}>
                                    <Button
                                    // loading={state?.loading}
                                    size='small'
                                    type='text'
                                    style={{background:'#F8F7FA', padding:'0 6px',  }}
                                    onClick={removePromotion}
                                    >
                                        <CloseOutlined style={{fontSize:12}}/>
                                    </Button>
                                </EntranceMotion>
                            </>
                        )
                    }
                    
                </div>
            </div>
        </>
    );
}

export default PromoCodeApplied;