import React, { useMemo } from 'react';
import NotFoundLayout from './components/not_found_layout';
import { useLocation } from 'react-router-dom';

const PurchaseNotFound = () => {
    //get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const queryItems = useMemo(() => {
        return {
            redirect_url : queryParams.get('redirect_url'),
            redirect_name : queryParams?.get('redirect_name'),
            title : queryParams?.get('title'),
            description : queryParams?.get('description'),
        }
    }, [queryParams])

    return (
        <>
            <NotFoundLayout queryItems={queryItems} />
        </>
    );
}

export default PurchaseNotFound;