import React, { useEffect, useMemo } from 'react';
import OpenDatePurchaseLayout from './components/open_date_purchase_layout';
import { useDispatch } from 'react-redux';
import { reset_purchase_detail } from '../../actions/purchase.action';
import { useLocation } from 'react-router-dom';

const OpenDateIndex = () => {
    const park_code = process.env.REACT_APP_PARK_CODE;

    const dispatch = useDispatch();

    //get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryItems = useMemo(() => {
        return {
            // special : queryParams.get('special'),
            default_promo_code : queryParams?.get('pc'),
        }
    }, [queryParams])


    useEffect(() => {
        return () => {
            //reset
            dispatch(reset_purchase_detail());
            //clear localstorage
            localStorage.removeItem('purchase_item');
        }
    }, [])
    
    return (
        <>
            <OpenDatePurchaseLayout park_code={park_code} queryItems={queryItems} />
        </>
    );
}

export default OpenDateIndex;