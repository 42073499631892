const initialState = {
    upload_image : null,
    special_link : null,
    liability_waiver_modal : false,
    is_add_on : false,
    park : null,

    // for promotion code
    remaining_promo_tickets : null,
    price_calculating : false,

    // capacity
    is_capacity : false,
    remaining_capacity : 0,

    // for integration pazzport
    ticket_saveable : true,

    // preference
    preference_groups : [],

    // auto set promotion code
    default_promo_code : null,
}

const ticketReducer = (state = initialState, action) => {
    switch(action.type){
        case 'UPLOAD_IMAGE':
            return {...state, upload_image : action.image}
        //for initialize special link
        case 'SET_SPECIAL_LINK' : 
            return {...state, special_link : action.special_link}
        case 'SET_LIABILITY_WAIVER' : 
            return {...state, liability_waiver_modal : action.liability_waiver_modal}
        case 'SET_IS_ADD_ON' : 
            return {...state, is_add_on : action?.is_add_on}
        case 'SET_PARK' :
            return {...state, park : action?.park, }
        case 'SET_REMAINING_PROMO_TICKETS':
            return {...state, remaining_promo_tickets : action?.remaining_promo_tickets, price_calculating : false, }
        case 'PRICE_CALCULATING' : 
            return {...state, price_calculating : action.loading, }
        case 'SET_TICKET_SAVEABLE' : 
            return {...state, ticket_saveable : action.ticket_saveable,}
        case 'SET_CAPACITY' :
            return {...state, remaining_capacity : action?.remaining_capacity, is_capacity : action?.is_capacity,}
        case 'SET_PREFERENCE' :
            return {...state, preference_groups : action?.preference_groups,}
        case 'SET_DEFAULT_PROMO_CODE' :
            return {...state, default_promo_code : action?.promo_code, }
        default :
            return state;
    }
}

export default ticketReducer;