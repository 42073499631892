import { Button, List, Space, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import numberHelper from '../../../helpers/functions/number.helper';

const OrderAddOnList = ({ discount_add_ons }) => {
    const purchaseRedux = useSelector(state => state.purchase);
    const ticketRedux = useSelector(state => state.ticket);
    const promotion_code = purchaseRedux?.promotion_code;
    const currency = ticketRedux?.park?.currency?.symbol;

    return (
        <>
            {
                purchaseRedux?.add_ons?.length > 0 &&
                (
                    <>
                        <div style={{textAlign:'left', paddingInline: 24, marginTop: 12, }}>
                            <span style={{fontWeight:500, fontSize:16, color:'var(--main-color)'}}>Add Ons</span>
                        </div>
                        <div>
                            <List
                            dataSource={purchaseRedux?.add_ons}
                            renderItem={(item, index) => {
                                const { name, price, quantity, add_on_id, add_on_setup_id } = item ?? {};

                                // check if promotion code apply for this ticket user
                                const discount_add_on = discount_add_ons?.find(x => x.add_on_setup_id === add_on_setup_id);

                                const is_applicable_promotion_code = (promotion_code?.promotion_code_add_ons?.find(x => x.add_on_setup_id === add_on_setup_id));
                                const promotion_price = price - discount_add_on?.discount_amount;
                                const cal_price = is_applicable_promotion_code ? promotion_price : price;
                                const sub_total = cal_price * quantity;
                                return (
                                    <List.Item style={{ paddingInline : 24, }}>
                                        <EntranceMotion initialY={-50} duration={1} style={{width : '100%'}}>
                                            <div style={{fontSize:14, display:'flex', textAlign:'left'}}>
                                                <div>
                                                    <div>
                                                        <span>{item?.name}</span>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span className='ticket-label' style={{fontWeight:500, fontSize:14}}>
                                                                    {`${currency}${numberHelper?.commaNumber(sub_total)}`}
                                                                </span>
                                                            </div>
                                                                {
                                                                    is_applicable_promotion_code &&
                                                                    (
                                                                        <>
                                                                            <div>
                                                                                <EntranceMotion>
                                                                                    <span className='ticket-label' style={{fontSize:12, textDecoration: 'line-through', color: 'var(--secondary-text-color)'}}>
                                                                                        {`${currency}${numberHelper?.commaNumber((price * quantity))}`}
                                                                                    </span>
                                                                                </EntranceMotion>
                                                                            </div>
                                                                            <div>
                                                                                <EntranceMotion delay={(0.1)}>
                                                                                    <Tag color='orange' style={{border:'none'}}>
                                                                                        {promotion_code?.promo_code}
                                                                                    </Tag>
                                                                                </EntranceMotion>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                }
                                                        </Space>
                                                    </div>
                                                </div>
                                                <div style={{marginLeft:'auto'}}>
                                                    <Space>
                                                        <div>
                                                            <span>
                                                                {quantity}
                                                            </span>
                                                        </div>
                                                    </Space>
                                                </div>
                                            
                                            </div>
                                        </EntranceMotion>
                                    </List.Item>
                                )
                            }}
                            />
                        </div>
                    </>
                )
            }
        </>
    );
}

export default OrderAddOnList;