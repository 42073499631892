import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getTicket(query){
    const queryString = serviceHelper.queryString(query);
    const ticketResponse = await axios.get(`ticket/get?${queryString}`, { withCredentials : false });
    return ticketResponse?.data;
}

async function getTicketUser(query){
    const queryString = serviceHelper.queryString(query);
    const ticketUserResponse = await axios.get(`ticket/user/get?${queryString}`, { withCredentials : false });
    return ticketUserResponse?.data;
}

async function calculateTicketPricing(params){
    const ticketPricingResponse = await axios.post(`ticket/price/calculate`, params, { withCredentials : false, });
    return ticketPricingResponse?.data;
}

async function updateTicketUser(ticket_user_no, params = {}, fields){
    const { name, age, gender, avatar, visit_date } = params;
    const form_data = new FormData();
    name && form_data.append('name', name);
    age && form_data.append('age', age);
    gender && form_data.append('gender', gender);
    visit_date && form_data.append('visit_date', visit_date);
    avatar && form_data.append('avatar', avatar);

    // dynamic field
    for(const field of fields){
        const { key } = field;
        const dynamic_form_data = params[key];
        dynamic_form_data && form_data.append(key, dynamic_form_data);
    }

    const ticketUserResponse = await axios.post(`ticket/user/update/${ticket_user_no}`, form_data,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});

    return ticketUserResponse?.data;
}

async function purchaseTicket(params = {}){ //register sales
    const purchaseTicketResponse = await axios.post(`ticket/purchase`, params, { withCredentials : true, });
    return purchaseTicketResponse?.data;
}

async function checkRules(params = {}){
    const checkRulesResponse = await axios.post(`ticket/rules`, params, { withCredentials : true });
    return checkRulesResponse?.data;
}

async function checkIsCheckedIn(ticket_id){
    const checkInResponse = await axios.get(`ticket/tk/${ticket_id}/check_in`, { withCredentials : true, });;
    return checkInResponse?.data;
}

async function getTicketField(ticket_user_no, query){
    const queryString = serviceHelper.queryString(query);
    const ticketFieldResponse = await axios.get(`ticket/user/${ticket_user_no}/field?${queryString}`, { withCredentials : true });
    return ticketFieldResponse?.data;
}

async function getPurchaseTaxes(query){
    const queryString = serviceHelper.queryString(query);
    const purchaseTaxesResponse = await axios.get(`ticket/taxes_by_purchase/get?${queryString}`, { withCredentials : true, });
    return purchaseTaxesResponse?.data;
}

export default {
    getTicket,
    getTicketUser,
    updateTicketUser,
    purchaseTicket,
    checkRules,
    checkIsCheckedIn,
    calculateTicketPricing,
    getTicketField,
    getPurchaseTaxes,
}